<template>
    <div class="attention-tips" :class="newUI ? 'attention-tips_new' : ''">
        <div class="attention-tips-title" @click="down">
            <div>注意事项</div>
            <Svgs
                v-if="!show && showMore && Array.isArray(tips)"
                color="#000000"
                name="iconarrow-top"
            ></Svgs>
            <Svgs
                v-if="show && showMore && Array.isArray(tips)"
                color="#000000"
                name="iconarrow-bottom"
            ></Svgs>
            
        </div>
        <div v-if="newUI" :class="{'showMore': showMore}" class="attention-tips-content">
            <div v-if="!Array.isArray(tips)" class="content-item">
                <p v-html="tips "></p>
            </div>
            <div v-else v-for="(item, index) in tips" :key="index" class="content-item">
                <p :class="{'showMore': showMore && show}" v-if="showMore && show? index == 0 : index >= 0">{{item.txt ? item.txt : item}}</p>
                <span v-if="showMore && index == 0 && show && item.length < widthLength">...</span>
            </div>
        </div>
        <div v-else :class="{'hidden': !show}" class="attention-tips-content">
            <div v-for="(item, index) in tips" :key="index" class="content-item">
                <div>{{index+1}}、</div><div>{{item}}</div></div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Tips",
    data() {
        return {
            show: true,
            showMore: false,
            widthLength: 0
        }
    },
    props: ['tips','newUI'],
    mounted() {
        let width = document.getElementsByClassName('attention-tips-title')[0].scrollWidth
        this.showMore = JSON.stringify(this.tips).length > width * 2 / 14 
        this.widthLength = width / 14  - 5
    },
    
    methods: {
        down() {
            this.show = !this.show
        }
    }
};
</script>

<style lang="scss">
.attention-tips_new{
    border-radius: 0.02rem!important;
    margin: 0 2.5% 0.2rem 2.5%!important;

}
.attention-tips {
    padding: .1rem 0.16rem;
    background: #f5f5f5;
    border-radius: 0.2rem;
    margin: 0 0.25rem 0.2rem 0.25rem;

    .attention-tips-title {
        text-align: left;
        font-size: 0.12rem;
        display: flex;
        justify-content: space-between;

        .icon {
            width: 0.12rem !important;
            height: 0.12rem !important;
            margin-left: 0.05rem;
        }
    }

    .hidden {
        max-height: 0 !important;
    }

    .attention-tips-content {
        color: #909399;
        font-size: 0.12rem;
        line-height: 0.18rem;
        margin-top: 0.06rem;
        transition: all .38s;
        max-height: 4rem;
        overflow: hidden;

        div {
            text-align: left;
        }

        .content-item {
            display: flex;
            p{
                text-align: left;
                font-size: .12rem;
            }
            .showMore{
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }
}

</style>